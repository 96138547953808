<template>
  <div class="calculator">
    <div class="answer">{{ result.lb }} lb {{ result.oz }} oz</div>
    <div class="display">{{ current }} kg</div>
    <div @click="append('7')" id="n7" class="btn">7</div>
    <div @click="append('8')" id="n8" class="btn">8</div>
    <div @click="append('9')" id="n9" class="btn">9</div>
    <div @click="append('4')" id="n4" class="btn">4</div>
    <div @click="append('5')" id="n5" class="btn">5</div>
    <div @click="append('6')" id="n6" class="btn">6</div>
    <div @click="append('1')" id="n1" class="btn">1</div>
    <div @click="append('2')" id="n2" class="btn">2</div>
    <div @click="append('3')" id="n3" class="btn">3</div>
    <div @click="clear" id="clear" class="btn operator">C</div>
    <div @click="append('0')" id="n0" class="btn">0</div>
    <div @click="append('.')" id="dot" class="btn">.</div>
  </div>
</template>

<script>
export default {
  name: "Converter",
  data() {
    return {
      result: { lb: 0, oz: 0 },
      current: "",
    };
  },
  methods: {
    append(number) {
      this.current = `${this.current}${number}`;
      this.updateResult();
    },
    updateResult() {
      let kg = parseFloat(this.current) || 0;
      let nearExact = kg / 0.45359237;
      let lb = Math.floor(nearExact);
      let oz = Math.round((nearExact - lb) * 16);
      if (oz == 16) {
        lb++;
        oz = 0;
      }
      this.result = { lb: lb, oz: oz };
    },
    clear() {
      this.current = "";
      this.updateResult();
    }
  }
};
</script>

<style>
.calculator {
  display: grid;
  grid-template-rows: repeat(6, minmax(70px, auto));
  grid-template-columns: repeat(3, 70px);
  grid-gap: 12px;
  padding: 35px;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 2px #0d5186;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #484848;
  background-color: #f4faff;
  border-radius: 5px;
  font-size: 2em;
}

.display,
.answer {
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
}

.display {
  color: #a3a3a3;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: clip;
}

.answer {
  font-weight: 100;
  color: #146080;
  font-size: 36px;
  height: auto;
}

.operator {
  background-color: #d9efff;
  color: #3fa9fc;
}
</style>